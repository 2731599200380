@import "antd/dist/reset.css";

.app-search-input .ant-input {
  border-radius: 500px;
}

.app-search-input .ant-input-search-button {
  border-left: transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 24px 16px 24px 8px !important;
  border-radius: 500px;
  width: 40px;
}

.app-search-input .ant-input:focus {
  border-right: transparent !important;
}

.app-search-input .ant-input-outlined {
  border-right: transparent !important;
}

.app-search-input .ant-input:hover {
  border-right-color: transparent !important;
}

.filter-checkbox {
  text-transform: capitalize;
}
.filter-checkbox .ant-checkbox-inner {
  border: 1px solid #000;
  background-color: transparent;
}
.filter-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
}

.form-item:not(.category) input {
  padding: 13px 16px !important;
}

.form-item label:before {
  content: "" !important;
  display: none !important;
}

.ant-pagination-item.ant-pagination-item-active {
  background-color: #d4eee5;
  border: 1px solid transparent;
}

.ant-pagination-prev button.ant-pagination-item-link {
  color: #fff;
}

.ant-pagination-next:not(.ant-pagination-disabled) button {
  color: #d4eee5;
}

.category-AutoComplete .ant-select-selector {
  padding: 13px 16px !important;
}

.category-AutoComplete .ant-select-selection-search {
  inset-inline-start: 16px !important;
}

.ant-menu-submenu.ant-menu-submenu-hidden.ant-menu-submenu-popup {
  padding-inline-start: 24px;
}

.ant-menu.ant-menu-sub li {
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #242424;
  border-radius: 8px;
  height: 48px;
}

.ant-menu.ant-menu-root > li {
  background-color: #ebcc6e;
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.filter-accordion .ant-collapse-content {
  background-color: transparent;
}
.filter-accordion .ant-collapse-item {
  border-bottom: 1px solid transparent;
}
.filter-accordion .ant-collapse-content {
  border-top: 1px solid rgb(235, 204, 110);
}

.admin-link-item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #242424;
  border-radius: 8px;
}
